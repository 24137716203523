html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'ES Allianz', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'ES Allianz';
  src: url('./fonts/ESAllianz-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ES Allianz';
  src: url('./fonts/ESAllianz-Extralight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ES Allianz';
  src: url('./fonts/ESAllianz-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ES Allianz';
  src: url('./fonts/ESAllianz-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ES Allianz';
  src: url('./fonts/ESAllianz-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ES Allianz';
  src: url('./fonts/ESAllianz-Extrabold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content {
  display: flex;
  flex-grow: 1;
  display: flex;
  flex-direction: column; 
  align-items: stretch;
}

.page-content > * {
    width: 100%;
  }

* {
  box-sizing: border-box;
}

.grid-container {
  display: flex;
  justify-content: space-between;
}

.grid-item {
  width: 48%;
  box-sizing: border-box;
  align-items: left;
}

.cl-rootBox {
  width: 100%;
}

.cl-formButtonPrimary {
  font-size: 22px;
  text-transform: none;
  background-color: #ceff1a;
  border-radius: 9999px;
  border-width: 1px;
  border: 1px solid #000 !important;
  padding: 16px 40px;
  box-shadow: 0px 0px 0px #000 !important;
  width: fit-content;
  align-self: start;
}

.cl-buttonArrowIcon {
  display: none;
}

.cl-formButtonPrimary:hover {
  background-color: white;
}

.cl-formFieldLabel__username-field {
  color: transparent;
  user-select: none;
  position: relative;
  width: 100%;
}

.cl-formFieldLabel__emailAddress-field {
  color: transparent;
  user-select: none;
  position: relative;
  width: 100%;
}

.cl-formFieldLabel__password-field {
  color: transparent;
  user-select: none;
  position: relative;
  width: 100%;
}

.cl-formFieldLabel__identifier-field {
  color: transparent;
  user-select: none;
  position: relative;
  width: 100%;
}

/* TODO: Consider removing these hacky placeholders so that we can use localization for labels instead
* Requires design changes*/
.cl-formFieldLabel__username-field:after {
  content: 'Username';
  position: absolute;
  left: 0px;
  top: 40px;
  color: #bbb;
}

.cl-formFieldLabel__emailAddress-field:after {
  content: 'Email address';
  position: absolute;
  left: 0px;
  top: 40px;
  color: #bbb;
}

.cl-formFieldInputShowPasswordButton {
  z-index: 11;
}

.cl-formFieldLabel__password-field:after {
  content: 'Password';
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: 1;
  color: #bbb;
}

.cl-formFieldLabel__identifier-field:after {
  content: 'Email address or username';
  position: absolute;
  left: 0px;
  top: 40px;
  color: #bbb;
}

.cl-formFieldInput {
  box-shadow: 0 1px 0 0 #000 !important;
  border-width: 1px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-bottom: 1px solid transparent !important;
  padding-left: 0;
}

.cl-formFieldInput:not(:placeholder-shown) {
  background-color: white;
  z-index: 10;
}
.cl-formFieldInput:hover {
  box-shadow: 0 3px 0 0 #000 !important;
  /* border-bottom: 3px solid #000 !important; */
}

.cl-formFieldInput:active {
  box-shadow: 0 3px 0 0 #ceff1a !important;
  /* border-bottom: 3px solid #ceff1a !important; */
}

.cl-formFieldInput:focus {
  /* border-bottom: 3px solid #ceff1a !important; */
  box-shadow: 0 3px 0 0 #ceff1a !important;
  z-index: 10;
}

.cl-formFieldInput.cl-error:hover {
  box-shadow: 0 3px 0 0 red !important;
  /* border-bottom: 3px solid red !important; */
}
.cl-formFieldInput.cl-error:active {
  box-shadow: 0 3px 0 0 red !important;
  /* border-bottom: 3px solid red !important; */
}

.cl-formFieldInput.cl-error:focus {
  box-shadow: 0 3px 0 0 red !important;
  /* border-bottom: 3px solid red !important; */
  z-index: 10;
}
.cl-formFieldInput.cl-error {
  box-shadow: 0 3px 0 0 red !important;
  /* border-bottom: 1px solid red !important; */
}
.cl-card {
  padding: 0;
}

.cl-cardBox {
  box-shadow: 0px 0px 0px #000;
  border: none;
  width: 100%;
  min-width: 20rem;
  max-width: 40rem;
}

/* FIXME: This causes an issue with our current Clerk setup.
* When user is receiving a magic link critical text is being hidden a */
.cl-header {
  display: none;
}

.cl-cardContent {
  padding: 0;
}

.cl-footer {
  display: none;
}

.cl-cardFooterActionLink {
  font-weight: '700';
  border-bottom: '3px solid';
  &:focus {
    box-shadow: 'none';
  }
}

.cl-socialButtons {
  display: flex;
  justify-content: start;
}

.cl-socialButtonsIconButton {
  border-radius: 9999px;
  width: 40px;
  height: 40px;
}
