.about-page {
    display: flex;
    flex-direction: row;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 950px;
    padding-left: 200px;
    padding-bottom: 50px;
}

.about-title {
    font-size: 50px;
}

.about-subheading {
    font-size: 25px;
}

.image {
    width: 750px;
}

.vertical-line {
    display: inline-block;
    border-left: 1px solid #030303;
    margin: 0 30px 150px 150px;
    height: 100%;
}

.profile-picture {
    width: 200px;
    height: 200px;
    border-radius: 100%;
}

.profile {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}