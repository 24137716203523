.clearableInput {
    width: 40%;
    min-width: 400px;
    position: relative;
    display: inline-flex;    
    border-radius: 4px;
    padding: 5px;
}

.clearableInput.focused {
    background-color: rgb(246, 244, 237);
    border: 1px solid #bcbcbc;
}