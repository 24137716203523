.resultPanel {
    padding-left: 2rem;
    transform: scaleX(-1);   
    
}

.leftSideScroll {
    position: relative;
    z-index: 2;
    transform: scaleX(-1);
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
    border-bottom: 1px solid #d3d3d3;
    box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
}

.navButton {
    height: 60px;
    display: inline-flex;
    padding: 10px;
    padding-left: 20px;
    border: 1px solid #777;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    margin-left: 1.5rem;
    margin-right: 2rem;
    font-size: 1.2rem;
    color: #777;
    text-transform: uppercase;
}

.navButton.disabled {
    opacity:.5;
}

.navButton.disabled:hover {
    background-color: inherit;
    cursor: inherit;
    border-color: inherit;
    color: inherit;
}

.navButton:hover {
    background-color: #CEFF1A;
    cursor: pointer;
    border-color: #333;
    color: #333;
}


.navButton svg {
    position: relative;
    left: 10px;
    width: 30px;
    height: 30px;
    transform: rotate(90deg);
    
}